<template>
  <v-container fluid pa-0 ma-0>
    <banner />
    <objective />
    <howto />
    <tree />
  </v-container>
</template>

<script>
// @ is an alias to /src
import objective from "../views/Objective.vue";
import banner from "../views/Banner.vue";
import howto from "../views/Howto.vue";
import tree from "../views/Tree.vue";

export default {
  components: {
    banner,
    objective,
    howto,
    tree
  }
};
</script>
