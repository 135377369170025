<template>
  <div class="bg-howto" ref="howto">
    <v-container justify-center>
      <v-row class="ta-c pa-0">
        <v-col md="12" lg="12">
          <h2 class="font-weight-bold white--text" id="howto">
            ขั้นตอนการขอรับกล้าไม้
          </h2>
        </v-col>
      </v-row>
      <v-divider class="mx-4" color="white" inset></v-divider>
      <v-row class="justify-center section-height">
        <v-col cols="12" md="6" lg="6" sm="12" xs="12">
          <v-tabs
            v-model="tab"
            centered
            icons-and-text
            center-active
            show-arrows
            background-color="primary"
            class="mt-4"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-0" class="">
              <span class="font-weight-bold fs-16">แบบจองออนไลน์</span>
              <v-icon large class="pa-1 mt-1">mdi-devices</v-icon>
            </v-tab>

            <v-tab href="#tab-1" class="">
              <span class="font-weight-bold fs-16">แบบ Walk in</span>
              <v-icon large class="pa-1 mt-1">mdi-walk</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(i, index) in 3"
              :key="i"
              :value="'tab-' + index"
            >
              <v-card flat>
                <v-card-text class="pa-1">
                  <div v-if="index == 0">
                    <v-timeline dense clipped small class="pb-0 ma-0">
                      <v-timeline-item
                        v-for="(item, index) in online"
                        :key="index"
                        fill-dot
                        class="pb-0 pt-0"
                      >
                        <template v-slot:icon>
                          <span class="font-weight-medium fs-14">{{
                            item.number
                          }}</span>
                        </template>
                        <v-row>
                          <v-col cols="12">
                            <span
                              class="font-weight-medium fs-16"
                              v-html="item.text"
                            ></span>
                          </v-col>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-btn
                          depressed
                          rounded
                          color="primary"
                          class="subtitle-1 d-flex justify-center align-center font-weight-bold"
                          @click="goto()"
                        >
                          จองกล้าไม้
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  <v-timeline dense clipped small v-else>
                    <v-timeline-item
                      v-for="(item, i) in walkin"
                      :key="i"
                      fill-dot
                      class="pa-0"
                    >
                      <template v-slot:icon>
                        <span class="font-weight-medium fs-16">{{
                          item.number
                        }}</span>
                      </template>
                      <v-row>
                        <v-col cols="12">
                          <span class="font-weight-medium fs-16">{{
                            item.text
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  components: {},
  data() {
    return {
      tabs: null,
      tab: null,
      listObj: [
        {
          icon: "mdi-devices",
          text: "การขอรับกล้าไม้แบบ Online"
        },
        {
          icon: "mdi-walk",
          text: "การขอรับกล้าไม้แบบ Walk in"
        }
      ],
      online: [
        {
          number: "1",
          text: "เลือกสถานที่รับกล้าไม้/ เลือกชนิดกล้าไม้"
        },
        {
          number: "2",
          text: "ระบุจำนวนที่ต้องการจอง"
        },
        {
          number: "3",
          text: "กรอกรายละเอียดผู้จอง และกดยืนยัน"
        },
        {
          number: "4",
          text: "ระบบจะแจ้งรหัสการจอง พร้อมทั้งส่งไปที่อีเมล"
        },
        {
          number: "5",
          text:
            "แจ้งรหัสการจอง พร้อมยื่นบัตรประชาชน<br> ณ หน่วยจ่ายกล้าไม้ที่เลือก ภายในวันที่กำหนด"
        }
      ],
      walkin: [
        {
          number: "1",
          text: "ยื่นบัตรประชาชน ณ หน่วยกล้าไม้ที่ขอรับ"
        },
        {
          number: "2",
          text: "แจ้งชนิดกล้าไม้ พร้อมระบุจำนวน"
        },
        {
          number: "3",
          text: "รับกล้าไม้"
        }
      ]
    };
  },
  methods: {
    goto() {
      window.open(
        "https://fp.forest.go.th/rfd_app/rfd_nursery/app/event_tree_search.php"
      );
    }
  }
};
</script>

<style lang="scss">
.bg-howto {
  background-image: url("../assets/images/bg4.jpg");
  background-size: cover;
  background-position: center;
}

.section-height {
  min-height: 500px;
}
</style>
