<template>
  <div>
    <!-- <v-row class="ta-c pa-0 justify-center">
      <v-col md="8" lg="8" cols="12">
        <h2
          class="font-weight-bold red accent-2 white--text pa-3"
          style="border-radius: 50px"
        >
          ประกาศ
        </h2>
      </v-col>
    </v-row> -->
    <v-row class="justify-center pa-2">
      <v-col cols="12" xs="12" md="9" lg="9">
        <v-card height="420" color="transparent" outlined>
          <v-carousel
            cycle
            :continuous="true"
            :show-arrows="true"
            height="400"
            hide-delimiter-background
            interval="12000"
            light
            show-arrows-on-hover
          >
            <v-carousel-item v-for="(i, index) in listAnnounces" :key="index">
              <v-row align="center" justify="center">
                <div
                  style="background-color: #fffff6ed; height:400px;display: flex;
    justify-content: center;width: 100%;"
                  class="pt-2"
                >
                  <v-alert
                    icon="mdi-bullhorn"
                    light
                    style="max-height:350px;overflow:auto;width: 80%;"
                    color="transparent"
                    class="ma-0"
                  >
                    <span v-html="i.text" class="black--text fs-18"></span>
                  </v-alert>
                </div>
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row
      class="justify-center"
      v-for="(i, index) in listAnnounces"
      :key="index"
      no-gutters
    >
      <v-col cols="12" xs="12" md="8" lg="6">
        <v-alert color="deep-orange lighten-3" icon="mdi-bullhorn">
          <span v-html="i.text"></span>
        </v-alert>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      listAnnounces: [
        {
          text: `<b>สำนักจัดการทรัพยากรป่าไม้ที่ 11 (สุราษฎร์ธานี)</b> แจ้งสถานที่รับกล้าไม้สำหรับผู้จองออนไลน์ จากเดิม รับที่สำนักฯ เปลี่ยนเป็น รับกล้าไม้ได้ที่ <b>สถานีเพาะชำกล้าไม้จังหวัดสุราษฎร์ธานี ต.นาสาร อ.บ้านนาสาร จ.สุราษฎร์ธานี โทร.สอบถาม 077-275-816 ต่อ 106</b>`
        },
        {
          text: `<b>สำนักจัดการทรัพยากรป่าไม้ที่ 11 (สุราษฎร์ธานี)</b> ขอรับกล้าไม้แบบ Walk in  ได้จำนวน 10 จุด ดังนี้<br>

1.หน่วยป้องกันรักษาป่าที่ สฎ.1 (ดอนสัก) ต.ปากแพรก  อ.ดอนสัก จ.สุราษฎร์ธานี<br>
2.หน่วยป้องกันรักษาป่าที่ สฎ.3 (บ้านตาขุน) ต.เขาวง  อ.บ้านตาขุน จ.สุราษฎร์ธานี<br>
3.หน่วยป้องกันรักษาป่าที่ สฎ.5 (ท่าฉาง) ต.ท่าเคย  อ.ท่าฉาง จ.สุราษฎร์ธานี<br>
4.หน่วยป้องกันรักษาป่าที่ สฎ.13 (คันธุลี) ต.คันธุลี  อ.ท่าชนะ จ.สุราษฎร์ธานี<br>
5.หน่วยป้องกันรักษาป่าที่ ชพ.1 (ยายไท) ต.หงษ์เจริญ  อ.ท่าแซะ จ.ชุมพร<br>
6.หน่วยป้องกันรักษาป่าที่ ชพ.2 (เสียบญวน) ต.วังใหม่  อ.เมือง จ.ชุมพร<br>
7.หน่วยป้องกันรักษาป่าที่ ชพ.3 (วิสัย) ต.วิสัยใต้  อ.สวี จ.ชุมพร<br>
8.หน่วยป้องกันรักษาป่าที่ ชพ.4 (หลังสวน) ต.วังตะกอ  อ.หลังสวน จ.ชุมพร<br>
9.หน่วยป้องกันรักษาป่าที่ รน.2 (ราชกรูด) ต.ราชกรูด  อ.เมือง จ.ระนอง<br>
10.หน่วยป้องกันรักษาป่าที่ รน.3 (จ.ป.ร.) ต.จปร  อ.กระบุรี จ.ระนอง
`
        },
        {
          text: `ประกาศเปลี่ยนแปลงสถานที่ติดต่อรับกล้าไม้ของ<br>สำนักจัดการทรัพยากรป่าไม้ที่ 2 (เชียงราย)</br><br>
         ทางสำนักจัดการทรัพยากรป่าไม้ที่ 2 (เชียงราย) ขอแจ้งเปลี่ยนแปลงสถานที่ติดต่อรับกล้าไม้เป็นหน่วยงานในสังกัด โดยท่านสามารถเลือกโทรติดต่อสอบถามเพื่อติดต่อขอรับกล้าไม้ในโครงการพฤกษามหามงคล กับหน่วยงานในสังกัดได้ ดังนี้<br>
         <b>1. สถานีเพาะชำกล้าไม้จังหวัดเชียงราย</b><br>
            ที่ตั้ง: 123 หมู่ 5 ต.แม่คำ  อ.แม่จัน จ.เชียงราย<br>
            เบอร์โทรติดต่อ:  081-950-6631<br>
         <b>2. สถานีเพาะชำกล้าไม้เวียงป่าเป้า จังหวัดเชียงราย</b><br>
            ที่ตั้ง: ต.แม่เจดีย์ใหม่ อ.เวียงป่าเป้า จ.เชียงราย<br>
             เบอร์โทรติดต่อ: 085-705-7029<br>
         <b>3. สถานีเพาะชำกล้าไม้จังหวัดพะเยา</b><br>
            ที่ตั้ง: หมู่ 6 ต.ท่าจำปี  อ.เมือง  จ.พะเยา<br>
             เบอร์โทรติดต่อ: 089-850-8269<br><br>

          ทางสำนักจัดการทรัพยากรป่าไม้ที่ 2 (เชียงราย) ต้องขออภัยในความไม่สะดวกมา ณ ที่นี้`
        },
        //         {
        //           text: `<b>การแจกกล้าไม้ "พฤกษามหามงคล" ในช่วงสถานการณ์โควิด 19</b><br>

        // กรมป่าไม้ขอความกรุณาผู้ที่จอง และรับกล้าไม้ ณ กรมป่าไม้ (จตุจักร กรุงเทพ)
        // <b>กรุณามารับกล้าไม้ภายในวันที่ 4 - 8 มกราคม 2564
        // เวลา 09.30-15.30 น. </b>
        // เนื่องจากกรมป่าไม้ต้องปฏิบัติตามมาตรการควบคุม
        // การแพร่ระบาดของไวรัสโคโรน่า (COVID19)
        // ตามการบริหารราชการในสถานการณ์ฉุกเฉิน
        // พ.ศ.2548 (ฉบับที่ 16) ลงวันที่ 3 มกราคม 2564
        // ข้อ 2 การห้ามจัดกิจกรรมที่มีความเสี่ยงต่อการแพร่โรค
        // จึงให้ผู้ขอรับกล้าไม้มารับกล้าไม้ภายในวันและเวลาดังกล่าว`
        //         },
        {
          text: `ขอเปลี่ยนแปลงสถานที่รับกล้าไม้ของ<b>สำนักจัดการทรัพยากรป่าไม้ที่ 4 สาขานครสวรรค์</b>
<br><b>กรณีจอง จุดรับกล้าไม้</b><br>
สถานีเพาะชำกล้าไม้จังหวัดนครสวรรค์
ม.4 ต.มหาโพธิ์ อ.เก้าเลี้ยว จ.นครสวรรค์
โทร 089-437-2862
<br><br>
<b>กรณี walk in</b> สามารถติดต่อรับกล้าไม้ได้ทุกสถานีฯ ในสังกัด ได้แก่<br>
1. สถานีเพาะชำกล้าไม้จังหวัดนครสวรรค์
   ม.4 ต.มหาโพธิ์ อ.เก้าเลี้ยว จ.นครสวรรค์
   โทร 089-437-2862<br>
2. โครงการปรับปรุงป่าสงวนแห่ชาติป่าแม่วงก์-แม่เปิน
    ม.7 บ้านวังชุมพร ต.แม่วงก์ อ.แม่วงก์ จ.นครสวรรค์
    โทร 081-785-6121<br>
3. สถานีเพาะชำกล้าไม้จังหวัดพิจิตร
     ม.3 ต.เขาเจ็ดลูก อ.ทับคล้อ จ.พิจิตร
     โทร 087-831-3166<br>
4. สถานีเพาะชำกล้าไม้จังหวัดอุทัยธานี
     ม.2 ถ.อุทัยธานี-หนองฉาง กม.15 ต.หนองนางนวล อ.หนองฉาง จ.อุทัยธานี
     โทร 089-586-7063<br>
5. สถานีเพาะชำกล้าไม้ลานสัก จังหวัดอุทัยธานี
      ม.9 ต.ลานสัก อ.ลานสัก จ.อุทัยธานี
      โทร 081-636-0178`
        },
        {
          text: `ขอเปลี่ยนแปลงสถานที่รับกล้าไม้ของ<b>สำนักจัดการทรัพยากรป่าไม้ที่ 9 (ชลบุรี)</b>
<br> เป็น<b> สถานีเพาะชำกล้าไม้จังหวัดชลบุรี ม.4 ต.บางพระ อ.ศรีราชา จ.ชลบุรี<br>โทร 081-834-6416</b>`
        },
        {
          text: `ขอเปลี่ยนแปลงสถานที่รับกล้าไม้ของ<b>สำนักจัดการทรัพยากรป่าไม้ที่ 10 สาขาเพชรบุรี</b>
 <br> เป็น <b>สถานีเพาะชำกล้าไม้จังหวัดเพชรบุรี ต.ชะอำ อ.ชะอำ จ.เพชรบุรี<br>โทร 081-705-0935, 096-153-3560</b>`
        },
        {
          text: `จองกล้าไม้ “พฤกษามหามงคล” ได้ตั้งแต่วันที่ 25 ธันวาคม 2563 เป็นต้นไป พร้อมนำถุงผ้า/ภาชนะมาใส่กล้าไม้ ขอสงวนสิทธิ์การจองท่านละ 1 ครั้ง กรุณารับกล้าไม้ภายในวันเวลาที่กำหนด`
        },
        {
          text: `กรมป่าไม้ ทำความดี ด้วยหัวใจ เลิกรับ เลิกให้ เลิกใช้ ถุงพลาสติกและโฟม`
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped></style>
