<template>
  <div class="bg-tree">
    <v-container justify-center>
      <v-row class="ta-c pa-0">
        <v-col md="12" lg="12">
          <h2 class="font-weight-bold" id="tree">กล้าไม้มงคล 10 ชนิด</h2>
        </v-col>
      </v-row>
      <v-row class="justify-center pa-0">
        <v-col cols="10">
          <carousel
            :autoplay="true"
            :nav="false"
            :loop="false"
            :margin="10"
            :responsive="{
              0: { items: 1, nav: false },
              320: { items: 1, nav: false },
              726: { items: 5, nav: false }
            }"
          >
            <v-card v-for="(i, index) in listObj" :key="index">
              <v-card-text class="ta-c">
                <a href="#" @click="openPdf(index + 1)">
                  <v-img :src="getImg(index + 1)" alt="" />
                </a>
              </v-card-text>
            </v-card>
          </carousel>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn
            depressed
            rounded
            color="primary"
            class="subtitle-1 d-flex justify-center align-center font-weight-bold"
            @click="goto()"
          >
            จองกล้าไม้
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  name: "Home",
  components: { carousel },
  data() {
    return {
      listObj: [
        {
          text: "พะยูง"
        },
        {
          text: "ขนุน"
        },
        {
          text: "มะขาม"
        },
        {
          text: "ตะเคียนทอง"
        },
        {
          text: "กันเกรา"
        },
        {
          text: "สัก"
        },
        {
          text: "ประดู่ป่า"
        },
        {
          text: "มะค่าโมง"
        },
        {
          text: "ราชพฤกษ์"
        },
        {
          text: "ทรงบาดาล"
        }
      ]
    };
  },
  methods: {
    getImg(image) {
      if (image === undefined) {
        return;
      } else {
        return require("../assets/tree/cover/Slide" + image + ".jpg");
      }
    },
    openPdf(index) {
      // console.log(index);
      var pdf = require("../assets/tree/pdf/" + index + ".pdf");
      window.open(pdf);
    },
    goto() {
      window.open(
        "https://fp.forest.go.th/rfd_app/rfd_nursery/app/event_tree_search.php"
      );
    }
  }
};
</script>

<style lang="scss">
.bg-tree {
  background-image: url("../assets/images/bg3.jpg");
  background-size: cover;
  background-position: center;
}
</style>
