<template>
  <div id="/">
    <!-- <img src="../assets/images/banner.jpg" width="100%" /> -->
    <v-img
      :src="getImg()"
      aspect-ratio="3"
      class="d-none d-md-block"
      @click="goto()"
      style="cursor:pointer"
    ></v-img>
    <v-img
      :src="getImg2()"
      aspect-ratio="1"
      class="d-md-none"
      @click="goto()"
      style="cursor:pointer"
    ></v-img>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  methods: {
    getImg() {
      return require("../assets/images/banner3.jpg");
    },
    getImg2() {
      return require("../assets/images/banner4.jpg");
    },
    goto() {
      window.open(
        "https://fp.forest.go.th/rfd_app/rfd_nursery/app/event_tree_search.php"
      );
    }
  }
};
</script>

<style lang="scss">
.top-70 {
  top: 70px;
}
</style>
