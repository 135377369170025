<template>
  <div class="bg-obj">
    <!-- <announce /> -->
    <!-- <statistic /> -->
    <news />

    <v-container justify-center>
      <v-row class="ta-c pa-0 pt-2">
        <v-col md="12" lg="12" cols="12">
          <h2 class="font-weight-bold">วัตถุประสงค์โครงการ</h2>
        </v-col>
      </v-row>

      <div class="d-none d-md-block">
        <v-container>
          <v-row class="justify-center pa-1">
            <v-col
              v-for="(i, index) in listObj"
              :key="index"
              cols="12"
              md="3"
              lg="3"
              style="justify-content: center;
    display: flex;"
            >
              <v-card
                height="350"
                width="300"
                color="secondary"
                class="pa-0 pt-5 rounded-xl"
                x
              >
                <v-card-text class="ta-c">
                  <v-icon x-large class="fs-80 mb-5 mt-5" color="brown"
                    >{{ i.icon }}
                  </v-icon>
                  <p
                    class="text--primary fs-20 font-weight-medium lh-30"
                    v-html="i.text"
                  ></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="d-md-none">
        <v-row class="ta-c pa-0 justify-center">
          <v-col cols="8">
            <carousel
              :items="1"
              :autoplay="true"
              :nav="false"
              :loop="false"
              :autoWidth="false"
            >
              <v-card
                height="300"
                width="auto"
                color="secondary"
                class="pa-0 pt-0"
                v-for="(i, index) in listObj"
                :key="index"
              >
                <v-card-text class="ta-c">
                  <v-icon x-large class="fs-60 mb-5 mt-3" color="brown"
                    >{{ i.icon }}
                  </v-icon>
                  <p
                    class="text--primary fs-18 font-weight-medium lh-30"
                    v-html="i.text"
                  ></p>
                </v-card-text>
              </v-card>
            </carousel>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
// import announce from "../views/Announce.vue";
import news from "../views/News.vue";
// import statistic from "../views/Statistic.vue";
export default {
  name: "Home",
  components: { carousel, news },
  data() {
    return {
      model: 0,

      listObj: [
        {
          icon: "mdi-gift",
          text:
            "มอบกล้าไม้มงคล<br>เป็นของขวัญในวันขึ้นปีใหม่<br>พุทธศักราช ๒๕๖๔<br>ให้แก่ประชาชนทั่วประเทศ"
        },
        {
          icon: "mdi-flare",
          text:
            "เสริมสร้างความเป็นสิริมงคล<br>ให้กับประชาชนที่นํากล้าไม้<br>กลับไปปลูกในพื้นที่ของตน"
        },
        {
          icon: "mdi-pine-tree",
          text: "เพิ่มพื้นที่สีเขียว<br>ให้กับประเทศไทย"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.bg-obj {
  background-image: url("../assets/images/bg3.jpg");
  background-size: cover;
  background-position: center;
}
</style>
